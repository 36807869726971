<template>
  <div class="data-user">
    <div class="data-user-left">
      <el-form>
        <el-form-item>
          <el-input
            size="small"
            v-model="searchForm.search"
            clearable
            placeholder="请输入关键词"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="small"
            v-model="searchForm.profile_id"
            clearable
            placeholder="请输入用户ID"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="small"
            v-model="searchForm.user_name"
            clearable
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input size="small" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input size="small" placeholder="请输入用户昵称"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button
            class="el-form-btn"
            type="primary"
            size="small"
            @click="searchFun"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <logCom
        :logList="logList"
        title="近10条查询记录"
        height="calc(100% - 80px)"
        attr="search"
        @selectLog="selectLog"
        ref="userlogCom"
      />
    </div>

    <div class="data-user-right" v-loading="loading">
      <totalAll v-if="list.length > 0" :text="'共检索到数据' + total + '条'" />

      <div
        class="box"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
        v-infinite-scroll="load"
      >
        <div class="right-box">
          <div class="right-item" v-for="(item, index) in list" :key="index">
            <div class="item-top">
              <img :src="item.avatar" alt="" v-show="item.avatar !== null" />
              <img :src="noPic" alt="" v-show="item.avatar == null" />
              <div>
                <div class="top-name">
                  {{
                    item.nick_name !== null
                      ? item.nick_name + '(昵称)'
                      : item.user_name
                  }}
                </div>
                <div class="top-sub">
                  {{
                    item.user_name !== null ? item.user_name + '（用户名）' : ''
                  }}
                </div>
              </div>
            </div>
            <div class="item-id">ID：{{ item.profile_id }}</div>
            <div class="item-content">
              <div class="info">
                <label>目前就职：</label>
                <div class="info-text">
                  {{ item.work | capitalize }}
                </div>
              </div>
              <div class="info">
                <label>曾经就读：</label>
                <div class="info-text">
                  {{ item.education | capitalize }}
                </div>
              </div>
              <div class="info">
                <label>居住地：</label>
                <div class="info-text">
                  {{ item.living | capitalize }}
                </div>
              </div>
              <!-- <div class="info">
              <label>来自：</label>
              <div class="info-text">
                {{ Array.isArray(item.living) ? item.living[1] : '' }}
              </div>
            </div> -->
              <div class="info">
                <label>联系方式：</label>
                <div class="info-text">
                  {{ item.contact | capitalize }}
                </div>
              </div>
              <div class="info">
                <label>基本信息：</label>
                <div class="info-text">
                  {{ item.basic | capitalize }}
                </div>
              </div>
              <div class="info">
                <label>人际关系：</label>
                <div class="info-text">
                  {{ item.relationship | capitalize }}
                </div>
              </div>
            </div>

            <div class="item-foot">
              <i
                class="el-icon-view border-line"
                @click="viewPosts(item.profile_id)"
              >
                查看帖子
              </i>
              <!-- <el-checkbox
              :key="index"
              :value="item.profile_id"
              v-model="checked[index]"
              @change="getuserid(item.profile_id, index)"
            >
              一键添加方案内容
            </el-checkbox> -->
              <a
                class="addBtn"
                :class="[
                  checkedPick.indexOf(index) != -1
                    ? 'checkedItem'
                    : 'unCheckedItem'
                ]"
                @click="selectPick(index, item.profile_id)"
              >
                添加方案内容
              </a>
            </div>
          </div>
        </div>
        <noMorePage :nomore="nomore" v-if="list.length > 0" />
      </div>

      <noData ref="noDataCom" v-if="list.length == 0" />
    </div>
    <div class="add-btn" v-if="btnVisible">
      <el-button type="primary" size="mini" @click="dialogVisible = true">
        添加到用户方案
      </el-button>
    </div>
    <el-dialog title="添加方案" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="search-user-ruleForm"
        >
          <el-form-item label="方案名称：" prop="name">
            <el-input
              placeholder="方案名称"
              v-model.trim="ruleForm.name"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="方案内容：">
            <div class="task-form-list" v-for="(item, i) in tasklist" :key="i">
              <el-input
                v-model.trim="item.name"
                size="small"
                class="user-task-input"
                disabled
              ></el-input>
              <el-input
                v-model.trim="item.remark"
                size="small"
                class="user-task-remark"
                placeholder="备注"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              placeholder="备注"
              v-model.trim="ruleForm.remark"
              type="textarea"
              size="small"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" style="textAlign:center">
        <el-button type="primary" @click="sureProgramme" size="small">
          确认提交方案
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addScheme,
  getScheme,
  getArticles,
  delScheme,
  updateScheme,
  delTask,
  setTask
} from '@/api/management.js'
import { facebookList } from '@/api/data.js'
import logCom from '../components/LogCom.vue'
export default {
  data() {
    return {
      noPic: require('@/assets/imgs/r.jpg'),
      searchForm: {
        search: '',
        profile_id: '',
        user_name: ''
      },
      logList: [], //记录
      list: [],
      current: 1,
      pageSize: 40,
      total: 0,
      nomore: true,
      loading: true,
      scrollDisabled: false,
      checklist: [],
      checked: false,
      dialogVisible: false,
      ruleForm: {
        name: '',
        remark: ''
      },
      btnVisible: false,
      checkedPick: [],
      tasklist: [],
      dialogTitle: 1,
      selectIndex: 1,
      rules: {
        name: [
          { required: true, message: '请输入方案名称', trigger: 'blur' },
          { min: 1, message: '方案名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return ''
      if (Array.isArray(value)) {
        return value.join(',')
      }
    }
  },
  components: {
    logCom
  },

  created() {
    this.getLocalStorageFun()
  },
  methods: {
    //选择添加方案内容获取用户id
    selectPick(index, userid) {
      const i = this.checkedPick.findIndex(item => item == index)
      console.log(i)
      if (i !== -1) {
        // console.log(this.checkedPick)
        this.checkedPick.splice(i, 1)
        this.checklist.splice(i, 1)
        this.tasklist.splice(i, 1)

        // console.log(this.tasklist)
      } else {
        //console.log(this.checkedPick)
        this.checkedPick.push(index)
        this.checklist.push(userid)
        this.tasklist.push({ name: '' })
        for (let i = 0; i < this.checklist.length; i++) {
          this.tasklist[i].name = this.checklist[i]
        }
        //console.log(this.checklist)
      }
      if (this.tasklist.length >= 1) {
        this.btnVisible = true
      } else if (this.tasklist.length < 1) {
        this.btnVisible = false
      }
      //console.log(this.tasklist.length)
    },
    //
    // 确认提交方案
    sureProgramme() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = Object.assign({}, this.ruleForm)
          obj.collection_type = 2
          let arr = this.tasklist.map(item => {
            item.target_type = 2
            item.target = item.name
            item.remark = item.remark
            return item
          })
          if (
            arr.some(item => {
              return item.target == ''
            })
          ) {
            this.$message({
              type: 'warning',
              message: '用户id不能为空！'
            })
            return
          }
          obj.tasks = arr
          this.postScheme(obj).then(res => {
            if (res.status == 201) {
              this.$message({
                type: 'success',
                message: '添加成功！'
              })
              this.dialogVisible = false
              //
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    postScheme(data) {
      // 添加方案接口
      return new Promise((resolve, reject) => {
        addScheme(data).then(res => {
          resolve(res)
        })
      })
    },
    // 查看帖子
    viewPosts(id) {
      this.$router.push({
        name: 'dataPosts',
        params: {
          user: id
        }
      })
    },
    // 查询
    searchFun() {
      this.current = 1
      this.list = []
      this.load()
      this.$refs.userlogCom.selectIndex = null
      this.localStorageFun()
      this.getLocalStorageFun()
    },
    // 记录
    selectLog(row) {
      this.searchForm = Object.assign({}, row)
      this.current = 1
      this.list = []
      this.load()
    },

    load() {
      // this.loading = true
      let obj = {
        search:
          this.searchForm.search.trim() == '' ? null : this.searchForm.search,
        profile_id: this.searchForm.profile_id,
        user_name: this.searchForm.user_name,
        page: this.current,
        page_size: this.pageSize
      }
      this.loading = true
      this.scrollDisabled = true
      facebookList(obj).then(res => {
        if (res) {
          this.total = res.data.count
          if (res.data.data.length > 0) {
            this.scrollDisabled = false
            this.current++
            this.list = this.list.concat(res.data.data)

            if (res.data.data.length < this.pageSize) {
              this.nomore = false
            }
          } else {
            this.nomore = false
          }
        } else {
          this.nomore = false
        }
        this.loading = false
      })
    },
    // 左侧记录
    localStorageFun() {
      let arrString = localStorage.getItem('facebookUserCommunity')
      let arr = JSON.parse(arrString)

      if (arr && Array.isArray(arr)) {
        if (arr.length < 10) {
          if (this.isEmpty(this.searchForm)) {
            arr.unshift(this.searchForm)
          }
        } else {
          if (this.isEmpty(this.searchForm)) {
            arr.unshift(this.searchForm)
          }
        }
      } else {
        arr = []
        if (this.isEmpty(this.searchForm)) {
          arr.unshift(this.searchForm)
        }
      }
      localStorage.setItem('facebookUserCommunity', JSON.stringify(arr))
    },
    getLocalStorageFun() {
      let arrString = localStorage.getItem('facebookUserCommunity')

      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        this.logList = [].concat(arr)
      }
      console.log(this.logList)
    },
    isEmpty(obj) {
      let arr = []
      for (let i in obj) {
        if (obj[i] && obj[i].trim() !== '') {
          arr.push(obj[i])
        }
      }
      if (arr.length == 0) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss">
.data-user {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .data-user-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-btn {
      width: 100%;
      border: 0px solid #000000;
      border-radius: 16px;
    }
  }
  .data-user-right {
    width: calc(100% - 270px);
    height: 100%;
    overflow: hidden;
    position: relative;
    > .box {
      width: 100%;
      height: calc(100% - 40px);
      overflow: hidden;
      overflow-y: auto;
      margin-top: 10px;
    }
    .right-box {
      width: calc(100% + 12px);
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .right-item {
        width: calc(100% / 4 - 10px);
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 10px;
        background: white;
        border-radius: 5px;
        border: 1px solid #e5e5e5;
        position: relative;
        .item-top {
          display: flex;
          img {
            vertical-align: middle;
            align-self: center;
            width: 48px;
            height: 48px;
            border-radius: 5px;
          }
          > div {
            align-self: center;
            margin-left: 12px;
            .top-name {
              font-size: 14px;
              font-weight: 400;
              color: #1a1a1b;
              margin-bottom: 7px;
              span {
                font-size: 12px;
              }
            }
            .top-sub {
              font-size: 12px;
              font-weight: 400;
              color: #555555;
            }
          }
        }
        .item-id {
          font-size: 12px;
          font-weight: 300;
          color: #1a1a1b;
          margin: 10px;
        }
        .item-content {
          margin-bottom: 40px;
          .info {
            white-space: nowrap;
            display: flex;
            margin-bottom: 5px;
            label {
              width: 60px;
              font-size: 12px;
              font-weight: 300;
              color: #878788;
              line-height: 22px;
            }
            .info-text {
              width: calc(100% - 60px);
              max-height: 40px;
              overflow: hidden;
              font-size: 12px;
              font-weight: 300;
              color: #1a1a1b;
              line-height: 22px;
              white-space: pre-wrap;

              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 这里是超出几行省略 */
              overflow: hidden;
            }
          }
        }
        .item-foot {
          width: 100%;
          text-align: center;
          border-top: 1px solid #f2f4f8;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 40px;
          i {
            font-size: 12px;
            font-weight: 300;
            color: #424243;
            margin: 10px 13px 0 0;
            cursor: pointer;
            font-style: inherit;
          }
        }
      }
    }
  }
  .add-btn {
    position: absolute;
    right: 20px;
    top: 13px;
    z-index: 999;
    .el-button {
      padding: 5px 10px;
    }
  }
  .search-user-ruleForm {
    .task-form-list {
      display: flex;
      justify-content: space-between;
    }
    .user-task-input {
      width: 40%;
    }
    .user-task-remark {
      width: calc(60% - 10px);
    }
  }
}
.comment-nomore {
  font-size: 14px;
  color: #606060;
  text-align: center;
  list-style: none;
}
.addMonit {
  border-left: 1px solid #989898;
  padding-left: 20px;
}
.addBtn {
  padding: 5px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 300;
}
.checkedItem {
  background-color: #4481e2;
  border-radius: 16px;
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.unCheckedItem {
  color: #424243;
  background-color: #fff;
  font-size: 12px;
}
.border-line {
  border-right: 1px solid #ddd;
  padding-right: 20px;
  margin-right: 10px;
}
</style>
